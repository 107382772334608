import React from "react";
import "./HeroImage.scss";

const HeroImage = ({ children }) => {
  return (
    <section className="container">
      <div className="container--row">{children}</div>
    </section>
  );
};

export default HeroImage;
