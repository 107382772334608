import { Link, graphql } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { Row } from "react-bootstrap";
import HeroImage from "../components/HeroImage/HeroImage";
import Layout from "../components/Layout";
import { recipes as data } from "../data/recipes";
import "./recipes.scss";

const Recipes = (props) => {
  const featuredImage = getImage(props.data.allFile.edges[0].node);
  const small = getImage(props.data.allFile.edges[3].node);
  const medium = getImage(props.data.allFile.edges[2].node);
  const large = getImage(props.data.allFile.edges[1].node);

  return (
    <Layout title="Recipes Archive">
      <HeroImage
        children={
          <>
            <figure>
              <GatsbyImage
                loading="lazy"
                className="container--row--large"
                image={large}
                alt="The Fried Bologna Sandwich"
              />
            </figure>
            <figure>
              <GatsbyImage
                loading="lazy"
                className="container--row--medium"
                image={medium}
                alt="The Fried Bologna Sandwich"
              />
            </figure>
            <figure>
              <GatsbyImage
                loading="lazy"
                className="container--row--small"
                image={small}
                alt="The Fried Bologna Sandwich"
              />
            </figure>
            <div className="container--row--title">
              <div class="recipeTitle">
                <h1>
                  <span class="bolognaWhite mediumTextbolognaDefault">our</span>
                  <span class="bolognaDefault mediumTextbolognaWhite">RECIPES</span>
                </h1>
              </div>
            </div>
          </>
        }
      />
      <Row md={3} xs={2} className="grid-wrapper recipeRow" as={"section"}>
        {data.map((item, i) => (
          <div className="grid-wrapper--grid">
            <Link
              className="grid-wrapper--grid--link"
              to={"/recipes/" + item.path}
            >
              <figure>
                <GatsbyImage
                  loading="lazy"
                  className="grid-wrapper--grid--link--image"
                  image={getImage(props.data.dataJson.recipes[i].recipeImage)}
                  alt={props.data.dataJson.recipes[i].recipeImageAlt}
                />
              </figure>
              <h2>
                <span className="grid-wrapper--grid--link--title">
                  {item.recipeTitle}
                </span>
              </h2>
              {item.featured ? (
                <figure>
                  <GatsbyImage
                    loading="lazy"
                    className="grid-wrapper--grid--link--featured"
                    image={featuredImage}
                    alt="Featured Recipe"
                  />
                </figure>
              ) : null}
            </Link>
          </div>
        ))}
      </Row>
    </Layout>
  );
};

export default Recipes;

export const images = graphql`
  query {
    allFile(
      filter: {
        relativePath: {
          in: [
            "featured.png"
            "recipes/recipes-s.jpg"
            "recipes/recipes-m.jpg"
            "recipes/recipes-l.jpg"
          ]
        }
      }
      sort: { order: ASC, fields: relativePath }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: WEBP
              webpOptions: { quality: 100 }
              quality: 100
            )
          }
        }
      }
    }
    dataJson {
      id
      recipes {
        id
        recipeImage {
          id
          base
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: WEBP
              webpOptions: { quality: 100 }
              quality: 100
            )
          }
        }
        recipeImageAlt
      }
    }
  }
`;
