import friedTitle from "../images/recipe-title_fried_bologna.png";
import tacosTitle from "../images/recipe-title_bologan-tacos.png";
import macTitle from "../images/recipe-title_poblano-mac-and-cheese.png";
import pastramiTitle from "../images/recipe-title_bologna-reuben.png";
import bbqTitle from "../images/recipe-title_bologna-bbq-sandwich.png";
import eggTitle from "../images/recipe-title_bologna-egg-sandwich.png";
import beneTitle from "../images/recipe-title_bologna-eggs-benedict.png";
import veggieTitle from "../images/recipe-title_bologna-asiago-panini.png";
import quicheTitle from "../images/recipe-title_bologna-quiche.png";
import totsTitle from "../images/recipe-title_bologna-tator-tots.png";

export const recipes = [
  {
    id: 0,
    recipeTitle: "Fried Bologna Sandwich",
    recipeTitleImage: friedTitle,
    recipeTitleImageAlt: "Fried Bologna Sandwich",
    featured: true,
    description:
      "This classic sandwich will soon become your everyday favorite. Melt a tablespoon of butter in a pan, add Wunderbar® Bologna and cook until sizzling and the edges are deliciously caramelized and crisp. Stack the warm bologna on a bun and immediately top with melty cheese for an even better sandwich than you remember!",
    recipeIngredients: [
      { quantity: "6 slices", ingredient: "Wunderbar® Bologna, thinly sliced" },
      { quantity: "1", ingredient: "brioche bun" },
      { quantity: "2 slices", ingredient: "cheddar cheese" },
    ],
    recipeSteps: [
      { step: "STEP 1", instruction: "Toast the bun." },
      {
        step: "STEP 2",
        instruction:
          "Add oil to a hot pan on medium heat and brown the bologna 1-2 minutes on each side.",
      },
      {
        step: "STEP 3",
        instruction: "Fold whole slices and lay them on the bottom bun.",
      },
      {
        step: "STEP 4",
        instruction: "Lay the cheese on bottom bun and on top of hot bologna.",
      },
      { step: "STEP 5", instruction: "Top with remaining half of bun." },
    ],
    path: "fried-bologna-sandwich/",
  },
  {
    id: 1,
    recipeTitle: "Bologna Tacos",
    recipeTitleImage: tacosTitle,
    recipeTitleImageAlt: "Bologna Tacos",
    featured: false,
    description: "",
    recipeIngredients: [
      { quantity: "2 slices", ingredient: "Wunderbar® Bologna, thick" },
      { quantity: "3", ingredient: "corn tortillas" },
      { quantity: "1/2 cup", ingredient: "apple cider vinegar" },
      { quantity: "1/4 cup", ingredient: "water" },
      { quantity: "1 tsp.", ingredient: "sugar" },
      { quantity: "1/4", ingredient: "avocado" },
      { quantity: "1 oz.", ingredient: "red onion, sliced" },
      { quantity: "1/2 oz.", ingredient: "queso fresco" },
      { quantity: "1Tbsp.", ingredient: "cilantro, chopped" },
      { quantity: "pinch", ingredient: "salt" },
    ],
    recipeSteps: [
      {
        step: "STEP 1",
        instruction:
          "Bring the water, vinegar and sugar to a boil. Place the onions in a heat-resistant glass bowl. Pour the vinegar over the onion and let rest for 30 minutes. Remove the onion and let cool on the counter. ",
      },
      {
        step: "STEP 2",
        instruction:
          "Grill the bologna on high heat for 1 - 2 minutes per side, or until hot.",
      },
      { step: "STEP 3", instruction: "Dice the avocado and bologna." },
      {
        step: "STEP 4",
        instruction: "Warm tortillas on the grill or microwave.",
      },
      {
        step: "STEP 5",
        instruction:
          "Add diced bologna to the tacos and follow with the onions and avocado. Add salt to taste.",
      },
      { step: "STEP 6", instruction: "Sprinkle cheese and cilantro on top." },
    ],
    path: "bologna-tacos/",
  },
  {
    id: 2,
    recipeTitle: "Poblano Mac & Cheese Bologna Cups",
    recipeTitleImage: macTitle,
    recipeTitleImageAlt: "Poblano Mac & Cheese Bologna Cups",
    featured: false,
    description: "",
    recipeIngredients: [
      { quantity: "4 slices", ingredient: "Wunderbar® Bologna, thinly sliced" },
      { quantity: "23 oz.", ingredient: "macaroni pasta" },
      { quantity: "1", ingredient: "poblano pepper, small" },
      { quantity: "1/4 oz.", ingredient: "butter" },
      { quantity: "1/4 oz.", ingredient: "flour" },
      { quantity: "3/4 cup", ingredient: "whole milk" },
      { quantity: "1/8 tsp.", ingredient: "salt" },
      { quantity: "1 Tbsp.", ingredient: "black pepper" },
      { quantity: "2 1/2 oz.", ingredient: "white cheddar cheese, shredded" },
      { quantity: "1 Tbsp.", ingredient: "bread crumbs, toasted" },
      { quantity: "1 Tbsp.", ingredient: "parsley, chopped" },
    ],
    recipeSteps: [
      {
        step: "STEP 1",
        instruction:
          "Place a pot of salted water on the stove and bring to a boil. Cook the macaroni according to the package instructions.",
      },
      {
        step: "STEP 2",
        instruction:
          "On the grill or in the oven, roast the poblano pepper until the skin blisters and chars. Place in a resealable plastic bag to rest. Once cool to the touch, dice the pepper and remove the seeds and stem.",
      },
      {
        step: "STEP 3",
        instruction:
          "In a small pot, melt the butter on medium-low heat and add flour. Mix well and cook for 3 - 4 minutes.",
      },
      {
        step: "STEP 4",
        instruction: "Slowly, add the milk and whisk so that no lumps form.",
      },
      {
        step: "STEP 5",
        instruction:
          "Add salt, pepper and poblano pepper. Let cook for about 15 minutes, careful not to scorch the sauce.",
      },
      {
        step: "STEP 6",
        instruction: "Mix in the cheese and add the cooked pasta.",
      },
      {
        step: "STEP 7",
        instruction: "Fold the bologna into a muffin tin or ramekins.",
      },
      {
        step: "STEP 8",
        instruction:
          "Fill the bologna cup with the macaroni and cheese and bake for about 10 minutes at 350°F.",
      },
      { step: "STEP 9", instruction: "Top with bread crumbs and parsley." },
      {
        step: "STEP 10",
        instruction: "Let cool for 5 - 8 minutes to allow the cups to set.",
      },
    ],
    path: "poblano-mac-&-cheese-bologna-cups/",
  },
  {
    id: 3,
    recipeTitle: "Bologna Reuben",
    recipeTitleImage: pastramiTitle,
    recipeTitleImageAlt: "Bologna Reuben",
    featured: false,
    description: "",
    recipeIngredients: [
      { quantity: "12 oz.", ingredient: "Wunderbar® Bologna, thinly sliced" },
      { quantity: "2 slices", ingredient: "marble rye bread" },
      { quantity: "2 slices", ingredient: "swiss cheese" },
      { quantity: "1 Tbsp.", ingredient: "softened butter" },
      {
        quantity: "1 Tbsp.",
        ingredient: "russian dressing or thousand island",
      },
      { quantity: "1/3 cup", ingredient: "sauerkraut, drained" },
      { quantity: "Pinch", ingredient: "pickling spices, finely grounded" },
    ],
    recipeSteps: [
      {
        step: "STEP 1",
        instruction: "Butter the outside of each slice of bread.",
      },
      { step: "STEP 2", instruction: "Turn skillet on medium-low heat." },
      {
        step: "STEP 3",
        instruction:
          "Layer the bologna on the bottom slice (unbuttered side) of bread.",
      },

      {
        step: "STEP 4",
        instruction:
          "Place cheese on top and spread the sauerkraut on the cheese.",
      },
      {
        step: "STEP 5",
        instruction: "Sprinkle the spice mix on the sauerkraut.",
      },
      {
        step: "STEP 6",
        instruction: "Spread dressing on the top bun and top of the sandwich.",
      },
      {
        step: "STEP 7",
        instruction:
          "Cook in the pan for 2 - 4 minutes per side, or until golden brown and hot.",
      },
    ],
    path: "bologna-reuben/",
  },
  {
    id: 4,
    recipeTitle: "Bologna Barbeque Sandwich",
    recipeTitleImage: bbqTitle,
    recipeTitleImageAlt: "Bologna Barbeque Sandwich",
    featured: false,
    description: "",
    recipeIngredients: [
      { quantity: "1 slice", ingredient: "Wunderbar® Bologna, thick" },
      { quantity: "1", ingredient: "artisan bun" },
      { quantity: "1/2 Tbsp.", ingredient: "mayonnaise" },
      { quantity: "1 Tbsp.", ingredient: "green onion, thinly sliced" },
      {
        quantity: "1/3 cup",
        ingredient: "slaw mix (green and red cabbage and carrots)",
      },
      { quantity: "2 Tbsp.", ingredient: "BBQ sauce" },
      { quantity: "4 - 5 dashes", ingredient: "tabasco sauce" },
    ],
    recipeSteps: [
      { step: "STEP 1", instruction: "Mix the green onion and slaw mix." },
      {
        step: "STEP 2",
        instruction:
          "In a small bowl, mix mayonnaise with a few dashes of tabasco. (add more tabasco if you like spicy)",
      },
      {
        step: "STEP 3",
        instruction: "Toss the slaw mix with the spicy mayo and set aside.",
      },
      {
        step: "STEP 4",
        instruction:
          "Heat your grill on high and grill the bologna 4 - 6 minutes on each side, or until hot and there are grill marks.",
      },
      {
        step: "STEP 5",
        instruction:
          "While the bologna is on the grill, place the bun cut side down on the grill for about 1 - 2 minutes to get a light toast and warm bun.",
      },
      {
        step: "STEP 6",
        instruction:
          "Warm the BBQ sauce for a few seconds in the microwave. Then, spread the sauce on the top and bottom bun.",
      },
      {
        step: "STEP 7",
        instruction:
          "Lay the bologna on the bottom bun. Then, pile the slaw on top of the bologna. Finish with the top bun.",
      },
    ],
    path: "bologna-barbeque-sandwich/",
  },
  {
    id: 5,
    recipeTitle: "The Ultimate Bologna Egg Sandwich",
    recipeTitleImage: eggTitle,
    recipeTitleImageAlt: "The Ultimate Bologna Egg Sandwich",
    featured: false,
    description: "",
    recipeIngredients: [
      { quantity: "2 slices", ingredient: "Wunderbar® Bologna, thick" },
      { quantity: "2 slices", ingredient: "multigrain bread" },
      { quantity: "1/4 cup", ingredient: "water" },
      { quantity: "1 tsp.", ingredient: "vinegar" },
      { quantity: "1/2 tsp.", ingredient: "salt" },
      { quantity: "1", ingredient: "egg" },
      { quantity: "1 slice", ingredient: "cheddar cheese" },
      { quantity: "1/2 Tbsp.", ingredient: "olive oil" },
      { quantity: "1/2 Tbsp.", ingredient: "spicy mayo" },
      { quantity: "1/2 oz.", ingredient: "arugula" },
      { quantity: "Pinch", ingredient: "fresh cracked pepper" },
    ],
    recipeSteps: [
      {
        step: "STEP 1",
        instruction:
          "In a medium non-stick skillet, add a couple of inches of water, just enough to cover the egg. Heat to 185° F. Add salt and vinegar.",
      },
      {
        step: "STEP 2",
        instruction:
          "Crack the egg into a small ramekin. Then, stir the water very gently and pour the egg in the center of the water. Remove from heat and cover for 5 minutes.",
      },
      {
        step: "STEP 3",
        instruction:
          "Toast the bread in the oven, lay the cheese slice on the bottom slice of bread and place back into the oven to melt the cheese. Then, lay bread slices side by side.",
      },
      {
        step: "STEP 4",
        instruction:
          "In a skillet, add oil and brown bologna 1 - 2 minutes on each side on medium heat.",
      },
      {
        step: "STEP 5",
        instruction: "Lay bologna on the bread with the cheese.",
      },
      {
        step: "STEP 6",
        instruction: "Spread the spicy mayo on the top slice of bread.",
      },
      { step: "STEP 7", instruction: "Spread arugula on the bologna." },
      {
        step: "STEP 8",
        instruction:
          "Place the poached egg on the arugula and crack some fresh pepper on it. Top with the other slice of bread.",
      },
    ],
    path: "the-ultimate-bologna-egg-sandwich/",
  },
  {
    id: 6,
    recipeTitle: "Bologna Eggs Benedict",
    recipeTitleImage: beneTitle,
    recipeTitleImageAlt: "Bologna Eggs Benedict",
    featured: false,
    description: "",
    recipeIngredients: [
      { quantity: "6 slices", ingredient: "Wunderbar® Bologna, thinly sliced" },
      { quantity: "1", ingredient: "english muffin" },
      { quantity: "2", ingredient: "eggs" },
      { quantity: "1 tsp.", ingredient: "white vinegar" },
      { quantity: "1/2", ingredient: "avocado" },
      { quantity: "1/4 cup", ingredient: "plain yogurt" },
      { quantity: "1 Tbsp.", ingredient: "milk" },
      { quantity: "1 Tbsp.", ingredient: "vegetable oil" },
      { quantity: "1 Tbsp.", ingredient: "cilantro, chopped" },
      { quantity: "1/2 tsp.", ingredient: "salt" },
      { quantity: "Pinch", ingredient: "cayenne pepper" },
    ],
    recipeSteps: [
      {
        step: "STEP 1",
        instruction:
          "Smash the avocado until mostly smooth, leaving some small pieces. Mix the avocado with yogurt and a pinch of salt. If it's too thick, add a splash of milk. The sauce should pour but not be runny.",
      },

      {
        step: "STEP 2",
        instruction:
          "Toast the english muffin and place both sides on a plate.",
      },
      {
        step: "STEP 3",
        instruction:
          "In a medium non-stick skillet, add a couple of inches of water, just enough to cover the eggs. Heat to 185° F and add salt and vinegar.",
      },

      {
        step: "STEP 4",
        instruction:
          "Crack each egg into individual ramekins. Then, pour the eggs in the water making sure not to let them touch. Remove pan from heat and cover for 5 minutes.",
      },

      {
        step: "STEP 5",
        instruction:
          "Remove eggs from water with a slotted spoon. Set them aside on a plate to drain any extra water.",
      },

      {
        step: "STEP 6",
        instruction:
          "Heat the oil in a skillet at medium heat. Brown the bologna on each side about 1 - 2 minutes. Cut into quarters and place 2 quarters on each muffin half.",
      },

      {
        step: "STEP 7",
        instruction:
          "Place one egg on the bologna on each side of muffin. Then, drizzle the sauce over the egg.",
      },

      {
        step: "STEP 8",
        instruction: "Sprinkle with cilantro and cayenne pepper.",
      },
    ],
    path: "bologna-eggs-benedict/",
  },
  {
    id: 7,
    recipeTitle: "Bologna Asiago & Olive Panini",
    recipeTitleImage: veggieTitle,
    recipeTitleImageAlt: "Bologna Asiago & Olive Panini",
    featured: false,
    description: "",
    recipeIngredients: [
      { quantity: "1 slice", ingredient: "Wunderbar® Bologna, thick" },
      { quantity: "1", ingredient: "ciabatta roll" },
      { quantity: "1 oz. ", ingredient: "zucchini" },
      { quantity: "1 1/4 oz.", ingredient: "eggplant" },
      { quantity: "1 Tbsp.", ingredient: "olive oil" },
      { quantity: "1/2 oz.", ingredient: "bell pepper, julienned" },
      { quantity: "Pinch", ingredient: "italian seasoning" },
      { quantity: "1 slice", ingredient: "asiago cheese" },
      { quantity: "1 Tbsp. ", ingredient: "softened butter" },
      { quantity: "1 Tbsp. ", ingredient: "black pepper" },
      { quantity: "Pinch ", ingredient: "salt" },
    ],
    recipeSteps: [
      {
        step: "STEP 1",
        instruction:
          'Slice the zucchini and eggplant into ¼" slices. Then, cut the eggplant slices into quarters.',
      },

      {
        step: "STEP 2",
        instruction:
          "Sprinkle the eggplant and zucchini lightly with salt and pepper.",
      },

      {
        step: "STEP 3",
        instruction:
          "In a large skillet on medium heat, add oil and cook the zucchini and eggplant until they start to soften and lose some water.",
      },

      {
        step: "STEP 4",
        instruction:
          "Add the bell pepper and italian seasoning. Cook for 3 - 4 minutes until bell pepper starts to soften.",
      },

      { step: "STEP 5", instruction: "Remove from heat and set aside." },
      {
        step: "STEP 6",
        instruction:
          "Place half of the cheese on the bottom slice of the roll. Add bologna and veggies. Then, sprinkle with italian seasoning.",
      },

      {
        step: "STEP 7",
        instruction: "Top veggies with the other half of cheese.",
      },
      {
        step: "STEP 8",
        instruction:
          "Place the top bun on and brush top and bottom with butter.",
      },
      {
        step: "STEP 9",
        instruction:
          "If you don’t have a panini press, use a skillet on medium-low heat and place a heavy skillet on the sandwich. Flip after 3 - 4 minutes and cook for another 3 - 4 minutes, or until cheese is melted.",
      },
    ],
    path: "bologna-asiago-&-olive-panini/",
  },
  {
    id: 8,
    recipeTitle: "Bologna & Gruyere Quiche",
    recipeTitleImage: quicheTitle,
    recipeTitleImageAlt: "Bologna & Gruyere Quiche",
    featured: false,
    description: "",
    recipeIngredients: [
      { quantity: "5 ½ oz.", ingredient: 'Wunderbar® Bologna, diced 1/4"' },
      { quantity: "1", ingredient: '9" pie crust' },
      { quantity: "4 oz.", ingredient: "gruyere cheese, diced" },
      { quantity: "6", ingredient: "eggs" },
      { quantity: "2 Tbsp.", ingredient: "green onion, chopped" },
      { quantity: "1 cup", ingredient: "milk" },
      { quantity: "1 Tbsp.", ingredient: "fresh thyme" },
      { quantity: "Pinch", ingredient: "salt" },
      { quantity: "Pinch", ingredient: "black pepper" },
    ],
    recipeSteps: [
      {
        step: "STEP 1",
        instruction:
          "Pre-bake the pie crust according to the package instructions.",
      },
      {
        step: "STEP 2",
        instruction: "Mix bologna, cheese and green onion together. ",
      },
      {
        step: "STEP 3",
        instruction: "Mix the eggs, milk, salt, pepper and thyme until smooth.",
      },
      {
        step: "STEP 4",
        instruction:
          "Pour mixtures into the shell and fill to the top of the crust but don't overfill.",
      },

      {
        step: "STEP 5",
        instruction: "Bake at 350° F for about 40 - 42 minutes.",
      },
      {
        step: "STEP 6",
        instruction: "Let cool for a few minutes before serving.",
      },
    ],
    path: "bologna-&-gruyere/",
  },
  {
    id: 9,
    recipeTitle: "Bologna-Wrapped Buffalo Tater Tots",
    recipeTitleImage: totsTitle,
    recipeTitleImageAlt: "Bologna-Wrapped Buffalo Tater Tots",
    featured: false,
    description: "",
    recipeIngredients: [
      {
        quantity: "6 slices",
        ingredient:
          'Wunderbar® Bologna, cut into four 2" strips (discard rounded edges)',
      },

      { quantity: "24", ingredient: "tater tots, at room temperature" },
      { quantity: "1 cup", ingredient: "blue cheese crumbles" },
      { quantity: "1/4 cup", ingredient: "buffalo sauce" },
      { quantity: "1/4 cup", ingredient: "carrots, grated" },
      { quantity: "1 tsp.", ingredient: "fresh thyme leaves, chopped" },
      { quantity: "1 cup", ingredient: "ranch dressing" },
      { quantity: "24", ingredient: "wooden toothpicks" },
    ],
    recipeSteps: [
      { step: "STEP 1", instruction: "Preheat oven at 400° F." },
      {
        step: "STEP 2",
        instruction:
          "Line a baking sheet with parchment paper or a silicone baking mat and set aside.",
      },

      {
        step: "STEP 3",
        instruction:
          "In a bowl, mix blue cheese, buffalo sauce, carrots and thyme to make a spreadable paste. For a less spicy version, use less buffalo sauce as desired.",
      },

      {
        step: "STEP 4",
        instruction:
          "Working one at a time, spread cheese spread around the tater tots sides.",
      },

      {
        step: "STEP 5",
        instruction:
          "Wrap each tater tot with a strip of bologna and secure by piercing toothpick through the side of the tater tot.",
      },

      { step: "STEP 6", instruction: "Repeat with remaining tater tots." },
      {
        step: "STEP 7",
        instruction:
          "Place tater tots same side down onto the prepared baking sheet.",
      },

      { step: "STEP 8", instruction: "Reduce oven to 350° F." },
      {
        step: "STEP 9",
        instruction:
          "Place into oven and bake for 6 minutes, using metal tongs to turn at halftime.",
      },

      {
        step: "STEP 10",
        instruction: "Serve immediately with ranch dressing.",
      },
    ],
    path: "bologna-wrapped-buffalo-tater-tots/",
  },
];
